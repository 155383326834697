import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useAddEditDocumentTypesDialogHooks } from "./AddEditDocumentTypeDialog.hooks";
import { DocumentType } from "../documentTypes/DocumentTypes.types";

export interface IAddEditDocumentTypeDialogProps {
    onDialogClose: () => void;
    isAdd: boolean;
    documentTypeToUpdate: DocumentType | undefined;
    onSaved: () => void;
}

function AddEditDocumentTypeDialog(props: IAddEditDocumentTypeDialogProps) {
    const { translateString } = useTranslation();
    const { isSaving, onModelChange, onSave, saveModel, validation } = useAddEditDocumentTypesDialogHooks(props);

    return (
        <DotLegalDialog
            size="sm"
            inProgress={isSaving}
            header={props.isAdd ? translateString("createDocumentType") : translateString("editDocumentType")}
            buttonOkText={props.isAdd ? translateString("create") : translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onSave}
        >
            <DotLegalTextField
                label={translateString("name")}
                value={saveModel.name}
                debounce={false}
                errorText={validation.getErrorsForDisplay("name")}
                onChange={(name) => onModelChange((model) => (model.name = name))}
            />
        </DotLegalDialog>
    );
}

export default AddEditDocumentTypeDialog;
