import { Box, Grid } from "@mui/material";
import React from "react";
import { DotLegalCheckbox, DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { DotLegalDocumentType } from "../../../documents/Documents.types";
import { useTranslation } from "../../../localization/useTranslation";
import { useEditLegalEntityDocumentHook } from "./EditLegalEntityDocumentDialog.hooks";
import { SelectedLegalEntityDocument } from "../LegalEntityDocumentTab.types";
import DocumentVersionTable from "../../../documents/documentVersionTable/DocumentVersionTable";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { StepTypeEnum } from "../../../processingActivity/ProcessingActivity.types";
import GroupEntityAcceesSelector from "../../groupEntityAccessSelector/GroupEntityAccessSelector";
import { DocumentTypeUsage } from "../../../masterData/documentType/documentTypes/DocumentTypes.types";

export interface IEditLegalEntityDocumentDialogProps {
    selectedDocument: SelectedLegalEntityDocument;
    onCloseDialog: () => void;
    isExtended: boolean;
    onSave?: () => void;
    stepType?: StepTypeEnum;
}

function EditLegalEntityDocumentDialog(props: IEditLegalEntityDocumentDialogProps) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const {
        document,
        setDocument,
        documentTypes,
        inProgress,
        validation,
        validateDocumentForm,
        userOptions,
        userOptionsIsLoading,
        isLoading,
        documentsUploading,
        setDocumentsUploading,
        documentStatus,
        onGroupEntityAccessIdsChange,
    } = useEditLegalEntityDocumentHook(props);

    validation?.check(document);

    //Hvis det på sigt bliver muligt at tilføje flere usages til en dokument type, så skal vi kunne håndtere det her
    // function getAddToSharingsCheckbox() {
    //     let documentType = documentTypes?.find((x) => x.id === document?.documentTypeId);

    //     if (documentType?.usages.some((x) => x === DocumentTypeUsage.DataProcessor)) {
    //         return addToSharingsCheckbox(translateString("adddataProcessorAgreementOnDataProcessor"));
    //     } else if (documentType?.usages.some((x) => x === DocumentTypeUsage.Tia)) {
    //         return addToSharingsCheckbox(translateString("addTransferImpactAssessmentOnDataProcessor"));
    //     } else if (documentType?.usages.some((x) => x === DocumentTypeUsage.JointDataController)) {
    //         return addToSharingsCheckbox(translateString("addJointDataControllerAgreementOnJointDataControllers"));
    //     } else if (documentType?.usages.some((x) => x === DocumentTypeUsage.Disclosure)) {
    //         return addToSharingsCheckbox(translateString("addDisclosureAgreementOnDisclosures"));
    //     }

    //     return undefined;
    // }

    const addToSharingsCheckbox = (label: string) => {
        return (
            <Box marginTop={2} marginBottom={2}>
                <DotLegalCheckbox
                    label={label}
                    checked={document?.addDocumentToSharings ?? false}
                    onChange={(add) => {
                        let tempModel = { ...document! };
                        tempModel.addDocumentToSharings = add;
                        setDocument(tempModel);
                    }}
                />
            </Box>
        );
    };

    return (
        <DotLegalDialog
            header={translateString("editDocument")}
            size="md"
            isLoading={false}
            buttonOkText={translateString("save")}
            open
            onDialogClose={() => props.onCloseDialog()}
            onOkClick={() => validateDocumentForm()}
            inProgress={inProgress}
            okButtonDisabled={documentsUploading}
            disableBackdropOnCloseIfValueChange={document}
        >
            <Grid container columnSpacing={2}>
                <Grid item md={12}>
                    <DotLegalTextField
                        label={translateString("name")}
                        value={document?.name!}
                        debounce={false}
                        onChange={(text) => {
                            let tempModel = { ...document! };
                            tempModel.name = text;
                            setDocument(tempModel);
                        }}
                        errorText={validation?.getErrorsForDisplay("name")}
                        isLoading={isLoading}
                    />
                </Grid>

                {props.isExtended && (
                    <Grid item md={12}>
                        <DotLegalSelect
                            selectedItem={document?.ownerId}
                            options={userOptions}
                            placeholder={translateString("owner")}
                            label={translateString("owner")}
                            onChange={(owner) => {
                                let tempModel = { ...document! };
                                tempModel.ownerId = owner;

                                setDocument(tempModel);
                            }}
                            isLoading={userOptionsIsLoading}
                            noOptionsLabel={translateString("noOptions")}
                        />
                    </Grid>
                )}

                <Grid item md={6}>
                    <DotLegalSelect
                        selectedItem={document?.type ? document?.type.toString() : undefined}
                        options={documentTypes}
                        placeholder={translateString("type")}
                        label={translateString("type")}
                        disableClearable
                        onChange={(type) => {
                            let tempModel = { ...document! };
                            tempModel.type = Number(type);
                            tempModel.addDocumentToSharings = false;
                            setDocument(tempModel);
                        }}
                        noOptionsLabel={translateString("noOptions")}
                        isLoading={isLoading}
                    />
                </Grid>

                {/* <Grid item md={6}>
                    <DotLegalSelect
                        selectedItem={document?.documentTypeId ? document?.documentTypeId.toString() : undefined}
                        options={documentTypes}
                        placeholder={translateString("type")}
                        label={translateString("type")}
                        disableClearable
                        onChange={(documentTypeId) => {
                            let tempModel = { ...document! };
                            tempModel.documentTypeId = documentTypeId ?? undefined;
                            tempModel.addDocumentToSharings = false;
                            setDocument(tempModel);
                        }}
                        noOptionsLabel={translateString("noOptions")}
                        isLoading={isLoading}
                    />
                </Grid> */}
                <Grid item md={6}>
                    <DotLegalSelect
                        selectedItem={document?.status ? document?.status.toString() : undefined}
                        options={documentStatus}
                        placeholder={translateString("status")}
                        label={translateString("status")}
                        disableClearable
                        onChange={(status) => {
                            let tempModel = { ...document! };
                            tempModel.status = Number(status);
                            setDocument(tempModel);
                        }}
                        noOptionsLabel={translateString("noOptions")}
                        disabled={!permissions.canSetDocumentStatus}
                        isLoading={isLoading}
                    />
                </Grid>
            </Grid>

            {props.isExtended && (
                <>
                    {document?.type === DotLegalDocumentType.DataProcessorAgreement &&
                        addToSharingsCheckbox(translateString("adddataProcessorAgreementOnDataProcessor"))}

                    {document?.type === DotLegalDocumentType.TIA && addToSharingsCheckbox(translateString("addTransferImpactAssessmentOnDataProcessor"))}

                    {document?.type === DotLegalDocumentType.JointDataControllerAgreement &&
                        addToSharingsCheckbox(translateString("addJointDataControllerAgreementOnJointDataControllers"))}

                    {document?.type === DotLegalDocumentType.DisclosureAgreement &&
                        addToSharingsCheckbox(translateString("addDisclosureAgreementOnDisclosures"))}
                </>
            )}

            <Box marginTop={4}>
                <DocumentVersionTable
                    documentId={props.selectedDocument.documentId}
                    documentsUploading={documentsUploading}
                    setDocumentsUploading={setDocumentsUploading}
                />
            </Box>

            {!props.selectedDocument.isGroupEntity && (
                <Box sx={{ marginTop: 4 }}>
                    <GroupEntityAcceesSelector
                        groupEntityAccessIds={document?.groupEntityAccessIds}
                        onGroupEntityAccessIdsChange={onGroupEntityAccessIdsChange}
                        allGroupEntities={document?.allGroupEntities}
                        isEdit
                        errorMsg={validation?.getErrorsForDisplay("groupEntityAccessIds")}
                    />
                </Box>
            )}
        </DotLegalDialog>
    );
}

export default EditLegalEntityDocumentDialog;
