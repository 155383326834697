import React from "react";
import { DotLegalDialog, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useChangeInternalAssessmentResponsibleDialogHooks } from "./ChangeInternalAssessmentResponsibleDialog.hooks";

export interface IChangeInternalAssessmentResponsibleDialog {
    responsible: string;
    internalAssessmentId: string;
    onDialogClose: () => void;
    onChangedResponsible: () => void;
}

function ChangeInternalAssessmentResponsibleDialog(props: IChangeInternalAssessmentResponsibleDialog) {
    const { translateString } = useTranslation();
    const { data, isLoading, responsible, isChanging, setResponsible, onChangeResponsibleClick } = useChangeInternalAssessmentResponsibleDialogHooks(props);

    return (
        <DotLegalDialog
            size="sm"
            buttonOkText={translateString("change")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onChangeResponsibleClick}
            isLoading={isLoading}
            header={translateString("changeResponsible")}
            inProgress={isChanging}
        >
            <DotLegalSelect
                options={data}
                onChange={(responsible) => setResponsible(responsible!)}
                selectedItem={responsible}
                label={translateString("responsibles")}
                noOptionsLabel={translateString("noOptions")}
                disableClearable
            />
        </DotLegalDialog>
    );
}

export default ChangeInternalAssessmentResponsibleDialog;
