import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useState, createElement } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post, put } from "../../../common/api/apiShared";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { IAddEditDocumentTypeDialogProps } from "./AddEditDocumentTypeDialog";
import { DocumentType } from "../documentTypes/DocumentTypes.types";
import { Result } from "../../../common/api/result";

export function useAddEditDocumentTypesDialogHooks(props: IAddEditDocumentTypeDialogProps) {
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<DocumentType>(props.documentTypeToUpdate ?? { name: "", id: "", isCustom: true });

    const snackBar = useDotLegalSnackbar();

    const validation = useValidator<DocumentType>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        }),
        [saveModel]
    );
    validation.check(saveModel);

    const onModelChange = (updateFunc: (model: DocumentType) => void) => {
        let tempSaveModel = { ...saveModel! };

        updateFunc(tempSaveModel);
        setSaveModel(tempSaveModel);
    };

    const saveMutation = useMutation(addDocumentType, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onSaved();
                snackBar.show(createElement(Trans, { i18nKey: "itemCreatedSuccess", values: { name: saveModel.name } }));
            } else {
                snackBar.show(translateString("documentTypeExists"), "warning");
            }
        },
    });

    const updateMutation = useMutation(updateDataType, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                snackBar.show(createElement(Trans, { i18nKey: "itemEditedSuccess", values: { name: saveModel.name } }));
            } else {
                snackBar.show(translateString("documentTypeExists"), "warning");
            }
        },
    });

    const onSave = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (props.isAdd) {
            await saveMutation.mutateAsync();
        } else {
            await updateMutation.mutateAsync(props.documentTypeToUpdate!.id);
        }

        props.onSaved();
    };

    function addDocumentType() {
        return post<string>("/DocumentTypes", saveModel);
    }

    function updateDataType(id: string) {
        return put<string>(`/DocumentTypes/${id}`, saveModel);
    }

    return {
        isSaving: saveMutation.isLoading || updateMutation.isLoading,
        validation,
        onModelChange,
        saveModel,
        onSave,
    };
}
